<template>
  <div :class="classes.layout" id="top">
    <intro-overlay />
    <div :class="$style.container" ref="container">
      <site-header />
      <!-- <transition name="t-view" mode="out-in" @before-enter="beforeEnter"> -->
      <keep-alive>
        <slot />
      </keep-alive>
      <!-- </transition> -->
    </div>
    <!-- <div :class="$style.box" id="box" /> -->
    <site-footer
      v-if="footerLoaded"
      :address="footer.address"
      :contact="footer.contact"
      @footer-height="handleFooterHeight"
    />
  </div>
</template>

<script>
import IntroOverlay from '@/components/intro-overlay.vue'
import SiteHeader from '@/components/site-header'
import SiteFooter from '@/components/site-footer'
import EventBus from '@/event-bus'
import { mapState } from 'vuex'
import { gsap } from 'gsap'

export default {
  components: { IntroOverlay, SiteHeader, SiteFooter },
  data() {
    return {
      footerLoaded: false,
      footer: {},
      currentItemHeight: 0,
      listHeight: 0,
      footerHeight: 0,
      containerHeight: 0,
      duration: 0.45,
      boxHeightPrevious: 0,
      boxHeight: 0,
      currentLink: {
        height: null,
        index: 0
      }
    }
  },
  computed: {
    ...mapState(['currentLanguage', 'introHidden', 'currentId']),
    classes() {
      return {
        layout: [
          this.$style.layout,
          this.currentLanguage != 'de' ? this.$style.otherLang : ''
        ]
      }
    },
    linkHeight() {
      return this.currentLink.height * this.currentLink.index
    }
  },
  methods: {
    calculateBoxHeight() {
      // if there is no item height OR item height but id set to null again, then ignore current height of item (= initial state)
      if (!this.currentItemHeight || !this.currentId) {
        this.boxHeight =
          window.innerHeight - this.containerHeight - this.footerHeight
      } else {
        // if item height is defined, use list and footer separately to enable tscrolling to top
        // NOTE: also add amount of links rendered before current item hierarchically seen, as they need to scroll up.
        this.boxHeight =
          window.innerHeight -
          this.listHeight -
          this.footerHeight -
          this.currentItemHeight +
          this.linkHeight
        // console.log('window', window.innerHeight)
        // console.log('list', this.listHeight)
        // console.log('footer', this.footerHeight)
        // console.log('current item', this.currentItemHeight)
        // console.log('final height', this.boxHeight)
      }
    },
    // beforeEnter() {
    //   this.$root.$emit('trigger-scroll')
    // },
    // get footer height from child comp footer
    handleFooterHeight(payload) {
      this.footerHeight = payload
    },
    animateBox() {
      gsap.fromTo(
        '#box',
        this.duration,
        {
          height: this.boxHeightPrevious
        },
        {
          height: this.boxHeight,
          onComplete: () => {
            this.boxHeightPrevious = this.boxHeight
          }
        }
      )
    }
  },
  mounted() {
    EventBus.$on('home-loaded', payload => {
      this.footer = payload
      this.footerLoaded = true
    })
    // pass evaluated item height from accordion-item to animation function
    EventBus.$on('current-item-height', payload => {
      this.currentItemHeight = payload
      // this.calculateBoxHeight()
      // this.animateBox()
    })
    // get list height from home
    EventBus.$on('list-height', payload => {
      this.listHeight = payload
    })

    EventBus.$on('current-link', payload => {
      // pass current link from accordion-link to add item height * index to box height
      this.currentLink.height = payload.height
      this.currentLink.index = payload.index
    })
  }
  // watch: {
  //   // initial calculation of box
  //   containerHeight() {
  //     this.calculateBoxHeight()
  //     this.animateBox()
  //   },
  //   introHidden() {
  //     // NOTE: nextTick was not sufficient enough to evaluate correct height
  //     setTimeout(() => {
  //       this.containerHeight = this.$refs.container.clientHeight
  //     }, 50)
  //   },
  //   // make sure to scroll back fully into view when id was resetted (= no element open again)
  //   currentId() {
  //     if (!this.currentId) {
  //       this.calculateBoxHeight()
  //       this.animateBox()
  //     }
  //   }
  // }
}
</script>

<style lang="scss" scoped>
// @include t-fade('t-view');
</style>

<style lang="scss" module>
.layout {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: var(--white);
  transition: background-color var(--mlong) ease;

  &.otherLang {
    background-color: var(--red);
  }
}

.container {
  flex: 1;
  width: 100%;
  max-width: var(--page-width);
  // min-height: 100%;
  padding: 0 var(--page-gutter);
  padding-top: var(--blank-line);
  margin: 0 auto;
}

// .box {
//   flex: 1;
// }
</style>
