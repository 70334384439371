<template>
  <header :class="classes.component" ref="header">
    <a href="#top" :class="$style.title" @click.prevent="scrollToTop">
      <img
        key="pos"
        v-if="currentLanguage === 'de'"
        src="@/assets/images/swissness-enforcement-logo-pos.svg"
        :class="$style.logo"
      />
      <img
        key="pos"
        v-else
        src="@/assets/images/swissness-enforcement-logo-neg.svg"
        :class="$style.logo"
      />
    </a>
    <language-switch class="language-switch" />
  </header>
</template>

<script>
import LanguageSwitch from '@/components/language-switch.vue'
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import { gsap } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)

export default {
  components: { LanguageSwitch },
  computed: {
    ...mapState(['currentLanguage', 'siteTitle']),
    classes() {
      return {
        component: [
          this.$style.component,
          this.currentLanguage != 'de' ? this.$style.otherLang : ''
        ]
      }
    }
  },
  methods: {
    setHeaderHeight: debounce(function() {
      this.$store.commit('setHeaderHeight', this.$refs.header.offsetHeight)
    }, 200),
    scrollToTop() {
      this.$router.push('')
      gsap.to(window, {
        duration: 0.8,
        scrollTo: '#top',
        ease: 'power1.easeInOut'
      })
      // TODO: This should close the open accordion items again and update the URL (see accordion-link.vue setCurrent() for reference)
    }
  },
  mounted() {
    this.setHeaderHeight()
    window.addEventListener('resize', this.setHeaderHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setHeaderHeight)
  }
}
</script>

<style lang="scss" module>
.component {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--gutter) 0;
  margin-bottom: 0.2em;

  @media (min-width: $medium) {
    position: sticky;
    top: 0;
    z-index: 10;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: var(--blank-line);
    background-color: var(--white);
    transition: background-color var(--mlong) ease;

    &.otherLang {
      background-color: var(--red);
    }
  }
}

.title {
  @extend %link-reset;

  width: 12em;

  // @media (min-width: $medium) {
  //   width: 12em;
  // }

  @media (min-width: $xlarge) {
    position: relative;
    left: -4.4em;
  }
}

.logo {
  transition: opacity var(--mlong) ease;
}
</style>
