<template>
  <ul :class="classes.component">
    <li
      v-for="language in availableLanguages"
      :key="language.name"
      :class="$style.item"
    >
      <language-button :language="language" />
    </li>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import LanguageButton from '@/components/language-button.vue'

export default {
  components: { LanguageButton },
  computed: {
    ...mapState(['availableLanguages', 'currentLanguage']),
    classes() {
      return {
        component: [
          this.$style.component,
          this.currentLanguage != 'de' ? this.$style.otherLang : ''
        ]
      }
    }
  }
}
</script>

<style lang="scss" module>
.component {
  @extend %fw-regular;

  position: relative;
  top: -0.2em;
  display: flex;
  justify-content: space-between;
  transition: color var(--mlong) ease;
}

.item {
  @extend %fs-title;

  color: var(--red);
  transition: color var(--mlong) ease;

  &:not(:last-child) {
    margin-right: 0.5em;

    &::after {
      @extend %fw-light;

      margin-left: 0.5em;
      content: '|';
    }
  }

  .otherLang & {
    color: var(--white);
  }
}
</style>
