<template>
  <footer :class="classes.component" ref="footer">
    <div :class="$style.inner">
      <div v-html="address" :class="$style.address" />
      <div v-html="contact" :class="$style.contact" />
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    address: {
      type: String,
      required: true
    },
    contact: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState(['currentLanguage']),

    classes() {
      return {
        component: [
          this.$style.component,
          this.currentLanguage != 'de' ? this.$style.otherLang : ''
        ]
      }
    }
  },
  mounted() {
    // send to default to calculate box height for scrolling
    this.$emit('footer-height', this.$refs.footer.offsetHeight)
  }
}
</script>

<style lang="scss" module>
.component {
  width: 100%;
  max-width: var(--page-width);
  padding: 0 var(--page-gutter) var(--gutter);
  margin: 0 auto;
  transition: border-color var(--mlong) ease;
}

.inner {
  padding-bottom: calc(var(--gutter) / 4);
  border-bottom: solid 3px var(--red);

  .otherLang & {
    border-color: var(--white);
  }

  @media (min-width: $medium) {
    display: flex;
    justify-content: space-between;
  }
}

.address,
.contact {
  @extend %fs-small;

  color: var(--red);
  transition: color var(--mlong) ease;

  .otherLang & {
    color: var(--white);
  }

  a {
    @extend %link-reset;

    &:hover {
      @extend %link-default;
    }
  }
}

.address {
  max-width: 18em;

  @media (min-width: $xsmall) {
    max-width: none;
  }
}
</style>
